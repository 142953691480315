<template>
   <v-col cols="12" sm="4" class="d-flex justify-content-between">
      <v-text-field
         :data-cy="dataCyInput"
         solo
         prepend-inner-icon="mdi-magnify"
         label="Search by Name"
         dense
         clearable
         flat
         color="primary"
         background-color="white lighten-4"
         v-model="query"
         @click:clear="resetData"
         @keyup.enter="searchAllowance"
      ></v-text-field>
      <v-btn
         :data-cy="dataCyBtn"
         class="ml-1"
         color="primary"
         @click.prevent="searchAllowance"
      >
         Search
      </v-btn>
   </v-col>
</template>

<script>
export default {
	name: "SearchInputAndButton",
	props: {
      dataCyInput: String,
      dataCyBtn: String,
      label: String,
   },
   methods: {
      searchAllowance() {
         this.$emit("search", this.query);
      },
      resetData() {
         this.query = "";
         this.$emit("reset-input", this.query);
      },
   },
   data() {
      return {
         query: "",
      };
   },
};
</script>
