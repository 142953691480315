export default { 
methods: {
      setProgress(startDate, endDate) {
        const currentDate = new Date();
        const [currentYear, currentMonth] = currentDate
          .toISOString()
          .slice(0, 7)
          .split("-");
        const [startYear, startMonth] = startDate.split("-");
        const [endYear, endMonth] = endDate.split("-");
  
        if (
          currentYear < startYear ||
          (currentYear === startYear && currentMonth < startMonth)
        ) {
          return "Pending";
        } else if (
          currentYear < endYear ||
          (currentYear === endYear && currentMonth <= endMonth)
        ) {
          return "In Progress";
        } else {
          return "Completed";
        }
      },
  
      setButtonColor(startDate, endDate) {
        const status = this.setProgress(startDate, endDate);
        switch (status) {
          case "In Progress":
            return "success";
          case "Pending":
            return "#2026000";
          case "Completed":
            return "#ff6f3f";
          default:
            return "primary";
        }
      },
}
}